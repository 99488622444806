import React, { forwardRef, useEffect, useMemo, useState, memo } from 'react'
import { useSite } from '../../foundation/hooks/useSite'
import {
  StyledTrustPilotReviewsModal,
  TrustPilotReviewsContainer,
  TrustPilotMicroComboContainer,
  TrustPilotCarouselContainer,
  TrustPilotWidgetContainer,
  SeeReviewContainer,
} from './TrustPilotWidget.style'
import { useTrustPilotWidgetLoader } from './useTrustPilotWidgetLoader'
import { useTranslation } from 'next-i18next'
import { SiteInfo } from '@redux/rootReducer'
import config from '@configs/index'

/*
 * Business unit id and template ids are provided by UX (via Trustpilot):
 *   Micro combo: https://trstp.lt/u1AqSJB7g
 *   Carousel: https://trstp.lt/z0YBnRNOi
 *   List: https://trstp.lt/qjk8khXnS
 */

const WIDGET_CONFIG = {
  BUSINESS_UNIT_ID: {
    'en-ww':
      '588ae68e0000ff00059b8cfb' /* Need to default en-WW to en-CA to prevent CMS preview from breaking CLY-1596 */,
    'en-ca': '588ae68e0000ff00059b8cfb',
    'fr-fr': '588ae68e0000ff00059b8cfb',
    'en-au': '5cff8d583aacbf000176c225',
    'en-nz': '5d91d4daa325070001a4c9e3',
  },
}

const TP_API_PRODUCT_REVIEWS_ENDPOINT = 'https://api.trustpilot.com/v1/product-reviews/business-units'

const TRUST_PILOT_ENABLED_LOCALES = ['en_ww', 'en_ca', 'fr_ca']

const TRUST_BOX_STYLE_DATA = {
  'data-font-family': 'Open Sans',
  'data-text-color': 'black',
  'data-link-color': '#1C4DA1',
  'data-no-reviews': 'collapse',
  'data-fullwidth': 'true',
  'data-third-party-reviews': 'true',
}

const TRUST_BOX_MINI_STYLE_DATA = {
  'data-no-reviews': 'collapse',
  'data-style-alignment': 'left',
}

enum WidgetTemplate {
  MICRO_COMBO = '5419b6ffb0d04a076446a9af',
  CAROUSEL = '53aa8912dec7e10d38f59f36',
  LIST = '539ad60defb9600b94d7df2c',
  REVIEW_BOX = '60f537b5b0f1639de1fe048c',
  MINI = '577258fb31f02306e4e3aaf9',
}

const TP_MINI_BAR_ID = 'tp-mini-bar-id'
const TP_MAIN_REVIEW_SECTION_ID = 'tp-main-review-section-id'

interface TrustPilotWidgetProps {
  templateId: string
  noScript?: boolean
  widgetTheme?: string
  attributes?: Record<string, string>
  widgetId?: string
}

export const isTrustPilotEnabledForLocale = (locale: string) => {
  return TRUST_PILOT_ENABLED_LOCALES.indexOf(locale) > -1
}

const getTrustPilotLocale = (mySite: SiteInfo) => {
  // TrustPilot does not work with fr-CA
  return mySite.locale === 'fr_ca' ? 'fr-fr' : mySite.locale.replace('_', '-')
}

const TrustPilotWidget = forwardRef<HTMLDivElement, TrustPilotWidgetProps>(
  ({ templateId, widgetId, noScript = false, widgetTheme, attributes = {} }, ref) => {
    const { mySite } = useSite()

    const locale = getTrustPilotLocale(mySite)
    const lang = mySite.locale.substring(0, 2)
    /* TODO: Mulish is not available on TrustPilot. We need an alternative that is listed */
    // const fontFamily = theme.typography?.fontFamily?.split(',')[0] || 'Muli'

    useEffect(() => {
      if (!noScript) {
        const script = document.createElement('script')
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
        document.head.appendChild(script)

        return () => {
          document.head.removeChild(script)
        }
      }
      return () => {}
    }, [noScript])

    return (
      <>
        <div
          ref={ref}
          id={widgetId}
          className="trustpilot-widget"
          data-locale={locale}
          data-review-languages={lang}
          data-template-id={templateId}
          data-businessunit-id={WIDGET_CONFIG.BUSINESS_UNIT_ID[locale]}
          data-theme={widgetTheme || 'light'}
          data-style-width="100%"
          data-style-height="100%"
          style={{ height: templateId === WidgetTemplate.MINI ? '20px' : '100%', width: '100%' }}
          {...attributes}
        ></div>
      </>
    )
  }
)

interface ReviewsModalProps {
  open: boolean
  handleClose: () => void
}

const TrustPilotReviewsModal: React.FC<ReviewsModalProps> = ({ open, handleClose }) => {
  const widgetRef = useTrustPilotWidgetLoader([open], !open)

  return (
    <StyledTrustPilotReviewsModal
      open={open}
      onClose={handleClose}
      onCloseButton={handleClose}
      widthSize={'sm'}
      roundBorders
    >
      <TrustPilotReviewsContainer>
        <TrustPilotWidget ref={widgetRef} templateId={WidgetTemplate.LIST} noScript={true} />
      </TrustPilotReviewsContainer>
    </StyledTrustPilotReviewsModal>
  )
}

export const TrustPilotMicroComboWidget: React.FC<{ id: string; widgetTheme?: string }> = memo(
  ({ id, widgetTheme }) => {
    const [showReviews, setShowReviews] = useState(false)

    useEffect(() => {
      /**
        This is to make it load even when there are multiple instances on same page. Each instance should have different 'id'.
        Reference: https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application#routing-in-single-page-applications-3
      */
      if (window.Trustpilot && id) {
        window.Trustpilot.loadFromElement(document.getElementById(id))
      }
    }, [id])

    return (
      <>
        <TrustPilotMicroComboContainer onClick={() => setShowReviews(true)} className="tp-microcombo-widget__container">
          <TrustPilotWidget
            templateId={WidgetTemplate.MICRO_COMBO}
            widgetTheme={widgetTheme}
            attributes={{ theme: widgetTheme || 'light', id }}
          />
        </TrustPilotMicroComboContainer>
        <TrustPilotReviewsModal open={showReviews} handleClose={() => setShowReviews(false)} />
      </>
    )
  }
)

export const TrustPilotCarouselWidget: React.FC = () => {
  const widgetRef = useTrustPilotWidgetLoader()

  return (
    <>
      <TrustPilotCarouselContainer className="tp-carousel-widget__container">
        <TrustPilotWidget ref={widgetRef} templateId={WidgetTemplate.CAROUSEL} />
      </TrustPilotCarouselContainer>
    </>
  )
}

export const TrustPilotMiniWidget: React.FC<TrustBoxProps> = (props: TrustBoxProps) => {
  const { sku } = props
  const { t: translate } = useTranslation()
  const { mySite } = useSite()
  const [isNoReview, setNoReview] = useState(true)

  const isZeroReviewForSku = (sku: string, locale: string): void => {
    const TP_IMPORTED_REVIEWS_SUMMARY_ENDPOINT = `${TP_API_PRODUCT_REVIEWS_ENDPOINT}/${WIDGET_CONFIG.BUSINESS_UNIT_ID[locale]}/imported-reviews-summaries?sku=${sku}`
    fetch(TP_IMPORTED_REVIEWS_SUMMARY_ENDPOINT, { headers: { apikey: config.trustPilot.apiKey } }).then(response => {
      response.json().then(res => {
        setNoReview(Number(res?.numberOfReviews?.total) === 0)
      })
    })
  }

  useMemo(() => {
    isZeroReviewForSku(sku, getTrustPilotLocale(mySite))
  }, [sku])

  useEffect(() => {
    /*
      Fallback if widget fails to load
      Reference: https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application#routing-in-single-page-applications-3
    */
    const tpElement = document.getElementById(TP_MINI_BAR_ID)
    if (window.Trustpilot && tpElement) {
      window.Trustpilot.loadFromElement(tpElement, true)
    }
  }, [mySite, isNoReview])

  return !isNoReview && isTrustPilotEnabledForLocale(mySite.locale) ? (
    <TrustPilotWidgetContainer>
      <span>
        <TrustPilotWidget
          templateId={WidgetTemplate.MINI}
          attributes={{
            'data-sku': sku,
            id: TP_MINI_BAR_ID,
            ...TRUST_BOX_MINI_STYLE_DATA,
          }}
        />
      </span>
      <SeeReviewContainer onClick={handleSeeReviewClick}>{translate('TrustPilotWidget.seeReviews')}</SeeReviewContainer>
    </TrustPilotWidgetContainer>
  ) : null
}

const handleSeeReviewClick = () => {
  document?.getElementById(TP_MAIN_REVIEW_SECTION_ID)?.scrollIntoView({ behavior: 'smooth' })
}

type TrustBoxProps = {
  sku: string
  productName?: string
}

export const TrustBox: React.FC<TrustBoxProps> = (props: TrustBoxProps) => {
  const trustBoxRef = React.useRef(null)
  const { sku, productName } = props

  useEffect(() => {
    if (window.Trustpilot && trustBoxRef.current) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true)
    }
  }, [])

  return (
    <TrustPilotWidget
      ref={trustBoxRef}
      widgetId={TP_MAIN_REVIEW_SECTION_ID}
      templateId={WidgetTemplate.REVIEW_BOX}
      noScript={true}
      attributes={{
        'data-sku': sku,
        'data-name': productName || '',
        ...TRUST_BOX_STYLE_DATA,
      }}
    />
  )
}
