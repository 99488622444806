import React, { useEffect } from 'react'

export const useTrustPilotWidgetLoader = (dependencyArray: any[] = [], skip = false): React.RefObject<HTMLDivElement> => {
  const widgetRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (skip) return

    const interval = setInterval(() => {
      if (widgetRef.current) {
        clearInterval(interval)
        window.Trustpilot.loadFromElement(widgetRef.current)
      }
    }, 200)

    return () => clearInterval(interval)
  }, dependencyArray)

  return widgetRef
}
