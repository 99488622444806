import styled from '@mui/material/styles/styled'
import { Button, StyledAnchor } from '../../../../components/UI'
import { Z_INDEX_LV4 } from '../../../../constants/ui'

const BENEFIT_BAR_HEIGHT = 40

export const WrapperBenefitBar = styled('div', {
  name: 'BenefitBar',
  slot: 'Wrapper',
})(({ theme }) => ({
  height: BENEFIT_BAR_HEIGHT,
  width: '100%',
  fontSize: 10,
  display: 'flex',
  backgroundColor: theme.palette.background.light.quinary,
  color: theme.palette.text.dark.primary,
  transition: `all ${theme.transitions.duration.standard}ms ease-in-out`,
}))

export const LeftBenefitBar = styled('div', {
  name: 'BenefitBar',
  slot: 'Left',
})(({ theme }) => ({
  display: 'inline-flex',
  height: 'inherit',
  width: '100%',
  justifyContent: 'center',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
    width: 'auto',

    '.tp-microcombo-widget__container': {
      marginLeft: '-15px',
    },
  },
}))

export const RightBenefitBarMyAccountWrapper = styled('div', {
  name: 'BenefitBar',
  slot: 'MyAccountWrapper',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  position: 'relative',
  alignItems: 'center',
  '.separator': {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    position: 'relative',
    top: '-2px',
  },
}))

export const ContentSlide = styled('div', {
  name: 'BenefitBar',
  slot: 'ContentSlide',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  textAlign: 'center',
  gap: theme.spacing(1),
  padding: `${theme.spacing(0)} ${theme.spacing(1.4)}`,
  overflowWrap: 'break-word',

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    padding: 0,
  },

  [theme.breakpoints.up('md')]: {
    marginTop: 0,
    display: 'flex',
    alignSelf: 'flex-start',
    padding: `0 ${theme.spacing(16)}`,
    marginLeft: 0,
  },

  [theme.breakpoints.up('lg')]: {
    alignSelf: 'flex-start',
    marginLeft: 0,
    padding: `0 ${theme.spacing(16)}`,
  },
}))

export const StyledCalendarButton = styled(Button, {
  name: 'StyledCalendar',
  slot: 'Button',
})(({ theme }) => ({
  height: BENEFIT_BAR_HEIGHT,
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: 700,
  padding: `0 ${theme.spacing(4)}`,
  ':lang(fr-ca)': {
    padding: `0 ${theme.spacing(6)}`,
  },
  [theme.breakpoints.down('md')]: {
    '&.MuiButtonBase-root': {
      width: theme.spacing(35),
    },
  },
}))

export const ContentTeaserText = styled('div', {
  name: 'BenefitBar',
  slot: 'TeaserText',
})(({ theme }) => ({
  display: 'inline',
  verticalAlign: 'middle',

  '& > div': {
    display: 'inline',
  },

  'div > p': {
    display: 'inline',
    maxWidth: '100vw',
    overflow: 'hidden',
    flexWrap: 'wrap',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    margin: 0,

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      maxWidth: 600,
      whiteSpace: 'nowrap',
      marginLeft: 0,
    },
  },
}))

export const ContentTeaserContainer = styled('div', {
  name: 'BenefitBar',
  slot: 'TeaserContainer',
})(({ theme }) => ({
  display: 'inline-block',
  width: 'auto',
  height: 'auto',
  verticalAlign: 'middle',
  margin: '0 auto',
  textAlign: 'center',
  lineHeight: 1,
  overflowWrap: 'break-word',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignSelf: 'flex-start',
    padding: `${theme.spacing(0)} ${theme.spacing(0)}`,
    lineHeight: 1,
    width: 'max-content',
    margin: 0,
  },
}))

export const ContentTeaserSpan = styled('span', {
  name: 'BenefitBar',
  slot: 'TeaserSpan',
})(({ theme }) => ({
  display: 'inline',
  verticalAlign: 'middle',
  width: 'max-content',
  whiteSpace: 'normal',

  '&.benefitTitles': {
    display: 'inline',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(0.6),
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

export const StyledBenefitButton = styled(Button, {
  name: 'BenefitBar',
  slot: 'Button',
})(({ theme }) => ({
  display: 'inline-block',
  background: 'transparent',
  border: 0,
  maxHeight: BENEFIT_BAR_HEIGHT,
  fontSize: theme.typography.subtitle1.fontSize,
  margin: 0,
  padding: 0,
  minWidth: 0,
  fontWeight: 700,
  color: theme.palette.text.dark.primary,
  overflow: 'visible',
  '&:hover': {
    border: 0,
    textDecoration: 'underline',
    overflow: 'hidden',
  },
}))

export const StyledAccountButton = styled(Button, {
  name: 'BenefitBar',
  slot: 'Button',
})(({ theme }) => ({
  display: 'inline-block',
  background: 'transparent',
  border: 0,
  maxHeight: BENEFIT_BAR_HEIGHT,
  fontSize: theme.typography.subtitle1.fontSize,
  margin: theme.spacing(0, 2, 0),
  padding: '8px',
  minWidth: 0,
  fontWeight: 700,
  color: theme.palette.text.dark.primary,
  overflow: 'visible',
  '&:hover': {
    border: 0,
    textDecoration: 'underline',
    overflow: 'hidden',
  },
  '& svg': {
    fill: 'currentColor',
  },
}))

export const StyledLanguageButton = styled(StyledBenefitButton, {
  name: 'BenefitBar',
  slot: 'LanguageButton',
  shouldForwardProp: prop => prop !== 'selected',
})<{ selected?: boolean }>(({ theme, selected = false }) => ({
  color: selected ? theme.palette.custom.blue : '',
  overflow: 'visible',
}))

export const BenefitLink = styled(StyledAnchor, {
  name: 'BenefitBar',
  slot: 'Link',
})(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  color: theme.palette.custom.blue,
  textDecorationColor: theme.palette.custom.blue,
  '&:visited': {
    color: theme.palette.custom.blue,
  },
  '&::after': {
    height: 0,
  },
}))

export const BenefitBarMyAccountPopUp = styled('div', {
  name: 'BenefitBar',
  slot: 'MyAccountPopUp',
  shouldForwardProp: prop => prop !== 'popupShow' && prop !== 'isCAStore',
})<{ popupShow: boolean; isCAStore?: boolean }>(({ theme, popupShow, isCAStore }) => ({
  display: popupShow ? 'block' : 'none',
  boxShadow: theme.shadows[9],
  position: 'absolute',
  top: theme.spacing(8),
  right: isCAStore ? 'auto' : theme.spacing(2),
  backgroundColor: theme.palette.background.light.primary,
  zIndex: Z_INDEX_LV4,

  Button: {
    textTransform: 'none',
    background: 'transparent',
    border: 'none',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.color.black,
    display: 'block',
    minWidth: 160,
    '&:hover': {
      background: 'none',
      border: 'none',
      color: theme.palette.text.dark.primary,
    },
  },
}))

export const ValidateIconWrapper = styled('div', {
  name: 'BenefitBar',
  slot: 'ValidateIconWrapper',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: theme.spacing(3),
  '> svg': {
    fill: theme.palette.color.black,
  },
  ':hover': {
    '> svg': {
      fill: theme.palette.primary.light,
    },
  },
}))
