import * as userAction from '../../../../redux/actions/user'

import {
  ACCOUNT,
  ACCOUNT_CHILDREN,
  CART,
  SIGNIN,
  STORELOCATOR,
  STORELOCATOR_FR,
  FRENCH_CA_LOCALE,
  WISHLIST,
  HOME,
} from '../../../../constants/routes'
import Axios, { Canceler } from 'axios'
import {
  BenefitBarMyAccountPopUp,
  BenefitLink,
  ContentSlide,
  ContentTeaserContainer,
  ContentTeaserSpan,
  ContentTeaserText,
  LeftBenefitBar,
  RightBenefitBarMyAccountWrapper,
  StyledBenefitButton,
  StyledCalendarButton,
  WrapperBenefitBar,
  StyledLanguageButton,
  StyledAccountButton,
} from './BenefitBar.style'

import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Autoplay } from 'swiper'
import { Button } from '../../../../components/UI'
import { Swiper, SwiperSlide } from 'swiper/react'
import { loginStatusSelector } from '../../../../redux/selectors/user'
import { setCloseDrawerMegaMenu } from '../../../../features/ui/action'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { wishlistEnabledSelector } from '../../../../features/wishList/selector'
import { useSite } from '../../../../foundation/hooks/useSite'
import {
  TrustPilotMicroComboWidget,
  isTrustPilotEnabledForLocale,
} from '../../../../components/TrustPilotReviews/TrustPilotWidget'
import { removeWishList } from '../../../../features/wishList/slice'
import { isCanadaStore } from '../../../../utils/storeUtil'
import { getInsuranceEventModule } from '../../../../components/DirectBilling'
import { useRouter } from 'next/router'
import { useIsAccountPage } from '@hooks/useIsAccountPage'
import styles from './styles/BenefitBar.module.scss'
import { CHECKOUT } from '@constants/common'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface IProps {
  data: IPlacement
  showLanguageSelector: boolean
  changeLanguage: Function
}

const BenefitBar: React.FC<IProps> = ({ data, showLanguageSelector, changeLanguage }) => {
  const dispatch = useDispatch()
  const { langCode } = useStoreIdentity()
  const isWishlistEnabled = useSelector(wishlistEnabledSelector)
  const loggedIn = useSelector(loginStatusSelector)
  const { t, i18n } = useTranslation()
  const { mySite } = useSite()
  const benefitBarItems = data?.items?.filter(isLXTeaser) ?? []
  const benefitBarName = data?.name ?? ''
  const [isPopUpShown, setIsPopUpShown] = useState<boolean>(false)
  const { interceptLogout } = getInsuranceEventModule()
  const router = useRouter()
  const isAccountPage = useIsAccountPage()

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const logOutUser = () => {
    const callback = () => {
      let payload = {
        ...payloadBase,
      }
      dispatch(userAction.LOGOUT_REQUESTED_ACTION(payload))
      dispatch(removeWishList())
      if (window.location.pathname.includes(CART) || window.location.pathname.includes(CHECKOUT)) {
        router.push(`/${langCode}/${CART}`)
      } else {
        dispatch(userAction.LOGOUT_IN_PROGRESS_ACTION(true))
        if (isAccountPage) {
          router.push(HOME)
        }
      }
    }

    interceptLogout(callback)
  }

  useEffect(() => {
    window.addEventListener('mouseup', function (event) {
      let dropdown = this.document.getElementById('benefit-bar_my-account-popup')
      let myAccountCta = this.document.getElementById('benefit-bar_my-account-cta')

      if (event.target !== dropdown && event.target !== myAccountCta) {
        setIsPopUpShown(false)
      }
    })
  }, [])

  const getSignInPath = (): string => {
    const isCartPage = location.pathname.endsWith(`/${CART}`)
    if (isCartPage) {
      const basePath = location.pathname.split(`/${CART}`)[0]
      return `/${langCode}/${SIGNIN}?redirectRoute=${basePath}/${CART}`
    }
    return `/${langCode}/${SIGNIN}`
  }

  const onSignInClick = () => {
    if (loggedIn) {
      setIsPopUpShown(!isPopUpShown)
    } else {
      router.push(getSignInPath())
    }
  }

  const onMyAccountClick = () => {
    if (loggedIn) {
      router.push(`/${langCode}/${ACCOUNT}/${ACCOUNT_CHILDREN.DASHBOARD}`)
    } else {
      router.push(getSignInPath())
    }
    dispatch(setCloseDrawerMegaMenu())
  }

  const isCAStore = isCanadaStore(mySite.locale)

  return (
    <WrapperBenefitBar
      data-cm-metadata={`[{"_":"properties.placement-${benefitBarName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${benefitBarName}"}]}]`}
    >
      <LeftBenefitBar>
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          direction={'vertical'}
          loop={true}
          slidesPerView={1}
          spaceBetween={60}
          modules={[Autoplay]}
          allowTouchMove={false}
        >
          {isTrustPilotEnabledForLocale(mySite.locale) && (
            <SwiperSlide key={-1}>
              <ContentSlide>
                <TrustPilotMicroComboWidget id="header-tp-widget" />
              </ContentSlide>
            </SwiperSlide>
          )}

          {benefitBarItems &&
            benefitBarItems.length > 0 &&
            benefitBarItems.map((item, index) => (
              <SwiperSlide key={index}>
                <ContentSlide>
                  <BenefitLink href={`${item?.teaserLXCallToActionSettings[0]?.target?.formattedUrl ?? ''}`}>
                    <ContentTeaserContainer>
                      <>
                        {item.teaserTitle1 && (
                          <ContentTeaserSpan className="benefitTitles">{`${item.teaserTitle1} - `}</ContentTeaserSpan>
                        )}
                        {item.teaserText1 && <ContentTeaserSpan>{item.teaserText1}</ContentTeaserSpan>}
                        {item.teaserText2 && (
                          <ContentTeaserText
                            dangerouslySetInnerHTML={{
                              __html: item.teaserText2,
                            }}
                          />
                        )}
                      </>
                    </ContentTeaserContainer>
                  </BenefitLink>
                </ContentSlide>
              </SwiperSlide>
            ))}
        </Swiper>
      </LeftBenefitBar>

      <div className={styles.rightBenefitBarWrapper}>
        <RightBenefitBarMyAccountWrapper>
          {loggedIn ? (
            <StyledAccountButton
              id={'benefit-bar_my-account-cta'}
              color={'inherit'}
              parentbg={'dark'}
              variant={'secondary'}
              iconposition={'left'}
              labeltext={t('Header.Actions.Account')}
              renderIcon={() => <SVGIcon library="global" name="account-user-logged" />}
              onClick={onSignInClick}
            ></StyledAccountButton>
          ) : (
            <StyledAccountButton
              color={'inherit'}
              parentbg={'light'}
              variant={'tertiary'}
              filltype={'outline'}
              radius={'none'}
              labeltext={t('Header.Actions.Login')}
              iconposition={'left'}
              renderIcon={() => <SVGIcon library="global" name="account-user" />}
              onClick={onMyAccountClick}
            ></StyledAccountButton>
          )}
          {showLanguageSelector && (
            <>
              <StyledLanguageButton
                data-element-id="MainNav_Language"
                color={'inherit'}
                parentbg={'light'}
                variant={'tertiary'}
                radius={'none'}
                filltype="outline"
                selected={i18n.language === 'en-ca'}
                onClick={() => changeLanguage('en', 'ca')}
              >
                {t('Header.Actions.SwitchEN')}
              </StyledLanguageButton>
              <span className="separator">|</span>
              <StyledLanguageButton
                color={'inherit'}
                parentbg={'light'}
                variant={'tertiary'}
                radius={'none'}
                filltype="outline"
                selected={i18n.language === 'fr-ca'}
                onClick={() => changeLanguage('fr', 'ca')}
              >
                {t('Header.Actions.SwitchFR')}
              </StyledLanguageButton>
            </>
          )}
          {isWishlistEnabled && loggedIn && (
            <StyledBenefitButton
              className={styles.benefitButton}
              color={'inherit'}
              onClick={() => router.push(`/${WISHLIST}`)}
            >
              {t('Header.Actions.WishList')}
            </StyledBenefitButton>
          )}
        </RightBenefitBarMyAccountWrapper>
        <BenefitBarMyAccountPopUp id={'benefit-bar_my-account-popup'} popupShow={isPopUpShown} isCAStore={isCAStore}>
          <Button
            data-element-id="MainNav_MyAccount"
            labeltext={t('Header.Actions.Account')}
            iconposition={'left'}
            // NOTE: inlining style to prevent button svg selectors from bleeding style onto icon
            renderIcon={() => <SVGIcon library="global" name="account-user-logged" style={{ fill: 'currentcolor' }} />}
            onClick={onMyAccountClick}
            variant="primary"
          />
          <Button
            labeltext={t('Header.Actions.Logout')}
            iconposition={'left'}
            // NOTE: inlining style to prevent button svg selectors from bleeding style onto icon
            renderIcon={() => <SVGIcon library="global" name="logout" style={{ fill: 'currentcolor' }} />}
            onClick={logOutUser}
          />
        </BenefitBarMyAccountPopUp>
        {isCAStore && (
          <StyledCalendarButton
            data-element-id="MainNav_BookExam"
            data-description="StoreLocator"
            variant="tertiary"
            filltype="fill"
            labeltext={t('Header.Actions.StoresAndEyeTest')}
            renderIcon={() => <SVGIcon library="global" name="calendar" />}
            iconposition={'left'}
            radius={'none'}
            parentbg={'light'}
            onClick={() => router.push(`/${langCode === FRENCH_CA_LOCALE ? STORELOCATOR_FR : STORELOCATOR}`)}
            neighbor={false}
          />
        )}
      </div>
    </WrapperBenefitBar>
  )
}

export default BenefitBar
