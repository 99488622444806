import styled from '@mui/material/styles/styled'
import { ModalDialog } from '../UI'

export const TrustPilotMicroComboContainer = styled('div', {
  name: 'TrustPilotMicroCombo',
  slot: 'Container',
})(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '420px',
  height: '20px',
  cursor: 'pointer',

  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },

  iframe: {
    width: '100%',
    pointerEvents: 'none',
  },
}))

export const TrustPilotCarouselContainer = styled('div', {
  name: 'TrustPilotCarousel',
  slot: 'Container',
})(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '100%',
  height: '300px',
  padding: theme.spacing(2),
  cursor: 'pointer',

  [theme.breakpoints.up('sm')]: {
    height: '160px',
  },

  iframe: {
    width: '100%',
    height: '100%',
  },
}))

export const TrustPilotWidgetContainer = styled('span', {
  name: 'TrustPilotWidget',
  slot: 'Container',
})(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const SeeReviewContainer = styled('span', {
  name: 'SeeReview',
  slot: 'Container',
})(({ theme }) => ({
  fontFamily: '"Helvetica Neue","Helvetica","Arial","sans-serif"', // This is to match fonts used by TrustPilot
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 500,
  width: '24%',
  textDecoration: 'underline',
  cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    width: '30%',
    paddingRight: theme.spacing(4),
  },
}))

export const StyledTrustPilotReviewsModal = styled(ModalDialog, {
  name: 'TrustPilotReviews',
  slot: 'Modal',
})(({ theme }) => ({
  '& .ModalDialog--wrapper': {
    overflowY: 'hidden',

    [theme.breakpoints.up('sm')]: {
      height: '600px',
      maxWidth: '640px',
    },
  },
}))

export const TrustPilotReviewsContainer = styled('div', {
  name: 'TrustPilotWidget',
  slot: 'ReviewsContainer',
})(() => ({
  width: '100%',
  height: '100%',
}))
